import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./layout.module.scss";

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;
  let header;

  if (isRootPath) {
    header = (
      <h1 className={styles.mainHeading}>
        <Link to="/">{title}</Link>
      </h1>
    );
  } else {
    header = (
      <Link className={styles.headerLinkHome} to="/">
        {title}
      </Link>
    );
  }

  return (
    <div className={styles.globalWrapper} data-is-root-path={isRootPath}>
      <header className={styles.globalHeader}>{header}</header>
      <main>{children}</main>
      <footer>
        <Link to="/">
          <StaticImage
            className={styles.footerLogo}
            layout="fixed"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../images/footer-logo.png"
            width={100}
            height={100}
            quality={95}
            alt="Footer logo"
            placeholder="blurred"
          />
        </Link>
      </footer>
    </div>
  );
};

export default Layout;
